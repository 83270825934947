import {Component} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import { CurrentUserService } from '@app/authentication/services/current-user.service';

@Component({
  selector: 'app-root',
  template: `
    <router-outlet></router-outlet>
    <app-loading></app-loading>
  `,
  styles: [`
    app-loading {
        display: none;
    }
    app-loading:nth-child(2) {
       display: block;
    }
  `
  ]
})
export class AppComponent {
  constructor(private translateService: TranslateService) {
    translateService.setDefaultLang('de');
    translateService.use('de');
  }
}
