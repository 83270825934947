import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { BytePipe } from './pipes/byte.pipe';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { ImgCompanyComponent } from '@shared/components/img-company/img-company.component';
import { FileGridItemComponent } from '@shared/components/file-grid-item/file-grid-item.component';
import { ErrorComponent } from './components/error/error.component';
import { ANT_UI_CONFIG, AntUiModule } from '@antony/ng-ui';
import { FileGridComponent } from './components/file-grid/file-grid.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import { LoadingComponent } from './components/loading/loading.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatInputModule } from '@angular/material/input';
import { getAntUiConfig } from '@app/data/ant-ui.config';
import { PropertyComponent } from './components/property/property.component';


@NgModule({
  declarations: [
    FileGridItemComponent,
    ImgCompanyComponent,
    BytePipe,
    NotFoundComponent,
    ErrorComponent,
    FileGridComponent,
    LoadingComponent,
    PropertyComponent
  ],
  imports: [
    CommonModule,
    MatCardModule,
    MatIconModule,
    TranslateModule,
    MatFormFieldModule,
    FormsModule,
    MatButtonModule,
    MatMenuModule,
    MatProgressSpinnerModule,
    MatInputModule,
    AntUiModule
  ],
  providers: [
    { provide: ANT_UI_CONFIG, useFactory: getAntUiConfig, deps: [TranslateService]}
  ],
    exports: [
        FileGridComponent,
        FileGridItemComponent,
        ImgCompanyComponent,
        NotFoundComponent,
        LoadingComponent,
        AntUiModule,
        PropertyComponent,
        ErrorComponent,
        BytePipe
    ]
})
export class SharedModule { }
